.adminprofilepage {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
  }
  .adminprofilepage_heading {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .adminprofilepage_heading > p {
    font-size: 22px;
    font-weight: 500;
  }
  .adminprofilepage_details {
    width: 100%;
    border: 1px solid lightgrey;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 5px;
  }
  .adminprofilepage_details>form{
      width: 100%;
      border: 1px solid lightgrey;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      padding: 10px;
  
  }
  .adminprofilepage_details>form>span{
      width: 100%;
      border: 1px solid lightgrey;
      margin-bottom: 20px;
      border-radius: 5px;
  }
  .adminprofilepage_details>form>p,.adminprofilepage_details>form>input{
      margin-bottom: 10px;
  }
  .adminprofilepage_details>form>span>input{
      width: 100%;
      height: 40px;
      padding-left: 10px;
      border: transparent;
      outline: transparent;
  }
  .adminprofilepage_details>form>button{
      padding: 10px;
      background-color: black;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
  }