.ourspacepage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ourspacepage_banner {
  width: 100%;
  height: 90vh;
  background-image: url("../imges/ezgif-4-568e505bef 1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.ourspacepage_banner > h3 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}
.ourspacepage_banner > span {
  border: 1px solid white;
  width: 35%;
  margin-bottom: 20px;
}
.ourspacepage_banner > p {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
}
.ourspacepage_first {
  margin-top: 60px;
  margin-bottom: 30px;
  width: 83%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.ourspacepage_first_left {
  flex: 0.6;
  border-right: 1px solid lightgray;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-right: 30px;
}
.ourspacepage_first_left_div {
  margin-top: 30px;
  background-color: #fff3ea;
  padding: 15px;
}
.ourspacepage_first_left_div > p {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: justify;
  word-break: break-word;
  line-height: 30px;
}
.ourspacepage_first_right {
  flex: 0.3;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.ourspacepage_first_right > h3 {
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 25px;
  font-weight: 400;
}
.ourspacepage_first_right > p {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 400;
}
@media only screen and (max-width: 600px) {
  .ourspacepage_banner {
    height: 70vh;
  }
  .ourspacepage_first {
    flex-direction: column;
    width: 90%;
  }
  .ourspacepage_first_left {
    padding: 0;
    border: none;
  }
  .ourspacepage_first_right > p {
    margin-bottom: 10px;
    font-size: 16px;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .ourspacepage_banner {
    height: 65vh;
  }
  .ourspacepage_banner > h3 {
    font-size: 30px;
  }
  .ourspacepage_banner > p {
    font-size: 16px;
  }
  .ourspacepage_first {
    width: 90%;
    flex-direction:column ;
  }

}
