.aboutuspage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.aboutuspage_banner {
  width: 100%;
  height: 40vh;
  background-image: url("../imges/Rectangle 93.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.aboutuspage_first {
  width: 83%;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.aboutuspage_second {
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 83%;
  margin-bottom: 30px;
}
.aboutuspage_second_left {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
}
.aboutuspage_second_left > img {
  height: 400px;
}
.aboutuspage_second_right {
  flex: 0.45;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.aboutuspage_second_right > h3,
.aboutuspage_third_left > h3 {
  font-size: 14px;
  font-weight: 500;
  color: #e68639;
  margin-bottom: 15px;
}
.aboutuspage_second_right_para {
  font-size: 29px;
  font-weight: 400;
  color: #b1b1b1;
  margin-bottom: 15px;
}
.aboutuspage_second_right_para1 {
  text-align: justify;
  word-break: break-word;
  margin-bottom: 15px;
  color: #313131;
  line-height: 30px;
}
.bottom_border {
  width: 60%;
  border: 1px solid lightgrey;
  margin-bottom: 30px;
}
.aboutuspage_third {
  width: 83%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 30px;
  margin-bottom: 30px;
}
.aboutuspage_third_left {
  flex: 0.45;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.aboutuspage_third_right {
  flex: 0.45;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;
}
.aboutuspage_third_right > img {
  height: 400px;
}
.aboutuspage_fourth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
}
.aboutuspage_fourth_heading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.aboutuspage_fourth_heading > h3 {
  font-size: 35px;
  font-weight: 400;
  color: #e68639;
}
.aboutuspage_fourth_image {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 30px;
}
.aboutuspage_fourth_image > span {
  flex: 0.3;
  width: 100%;
}
.aboutuspage_fourth_image > span > figure {
  width: 100%;
}
.aboutuspage_fourth_image > span > figure > img {
  width: 100%;
}
figure {
  display: grid;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
}
figure > * {
  grid-area: 1/1;
  transition: 0.4s;
}
figure figcaption {
  display: grid;
  align-items: end;
  font-family: sans-serif;
  font-size: 2.3rem;
  font-weight: bold;
  color: #0000;
  padding: 0.75rem;
  background: var(--c, rgba(70, 69, 69, 0.6));
  clip-path: inset(0 var(--_i, 100%) 0 0);
  -webkit-mask: linear-gradient(#000 0 0), linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  -webkit-mask-clip: text, padding-box;
  margin: -1px;
}
figure:hover figcaption {
  --_i: 0%;
}
figure:hover img {
  transform: scale(1.2);
}
@supports not (-webkit-mask-clip: text) {
  figure figcaption {
    -webkit-mask: none;
    color: #fff;
  }
}

.aboutuspage_fourth_para {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.aboutuspage_fourth_para > p {
  margin-bottom: 15px;
  text-align: justify;
  word-break: break-word;
  color: #313131;
  font-size: 15px;
  font-weight: 400px;
  line-height: 25px;
}
.aboutuspage_five {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
}
.aboutuspage_five > img {
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .aboutuspage_banner {
    height: 10vh;
  }
  .aboutuspage_first {
    margin-bottom: 20px;
    width: 90%;
  }
  .aboutuspage_second {
    width: 90%;
    flex-direction: column;
  }
  .aboutuspage_second_left > img {
    height: 235px;
    margin-bottom: 20px;
  }
  .aboutuspage_third {
    width: 90%;
    flex-direction: column-reverse;
  }
  .aboutuspage_third_right > img {
    height: 230px;
    margin-bottom: 20px;
  }
  .aboutuspage_fourth {
    width: 90%;
  }
  .aboutuspage_five {
    width: 90%;
  }
  .blogpage_banner {
    height: 50vh;
  }
  .aboutuspage_fourth_image{
    /* overflow-x: scroll; */
    align-items: flex-start;
    justify-content: flex-start;
  }
  .aboutuspage_fourth_image>span{
    flex: 1;
  }
  .aboutuspage_fourth_image>span>figure>img{
    width: 350px;
  }
  .aboutuspage_fourth_para{
    margin-top: 0px;
  }
  .aboutuspage_five {
    margin-top: 0px;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .aboutuspage_banner {
    height: 25vh;
  }
  .aboutuspage_first {
    width: 90%;
  }
  .aboutuspage_second {
    flex-direction: column;
    width: 90%;
  }
  .aboutuspage_second_left {
    width: 100%;
    flex: 1;
    margin-bottom: 20px;
  }
  .aboutuspage_second_left > img {
    width: 100%;
  }
  .aboutuspage_third {
    flex-direction: column-reverse;
    width: 90%;
  }
  .aboutuspage_third_right {
    width: 100%;
    margin-bottom: 20px;
  }
  .aboutuspage_third_right > img {
    width: 100%;
  }
  .aboutuspage_fourth {
    width: 90%;
  }
}
