.homebanner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* background: url("../../imges/ezgif-4-568e505bef 1.gif"); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 90vh;
  position: relative;
  margin-bottom: 30px;
  position: relative;
  border-bottom: 100px solid white;
}

.homebanner_para {
  color: #fff;
  font-size: 60px;
  font-weight: 400;
  /* margin-bottom: 50px; */
  /* position: absolute;
    top: 150px; */
  /* padding-bottom: 20px; */
  border-bottom-width: 60%;
  margin-bottom: 30px;
  width: 100%;
}
.homebanner_para1 {
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  margin-bottom: 80px;
  /* position: absolute;
    top: 250px; */
}
.homebanner > div > button {
  padding: 10px 15px;
  font-size: 18px;
  font-weight: 500;
  border: transparent;
  outline: transparent;
  border-radius: 5px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.homebanner > div > button:hover {
  opacity: 0.8;
}
.homebanner_span {
  border: 1px solid #fff;
  width: 40%;
  margin-bottom: 30px;
}

.homebannerSubDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 600px) {
    .homebanner{
        height: 50vh;
        border-bottom: 0px;
    }
  .homebanner_para {
    font-size: 30px;
    margin-bottom: 0px !important;
  }
  .homebanner_span {
    width: 50%;
    margin-bottom: 0px !important;
  }
  .homebanner_para1 {
    font-size: 14px;
    margin-bottom: 0px !important;
  }
  .homebannerSubDiv {
    /* display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-direction: column !important; */
    gap: 10px;
  }
  
}
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .homebanner {
    height: 65vh;
    border-bottom: 0px;
  }
  .homebanner_para {
    font-size: 40px;
  }
  .homebanner_span {
    width: 50%;
    
  }
  .homebanner_para1 {
    font-size: 16px;
  }
}
