.adminorderpage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.adminorderpage_heading {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.adminorderpage_heading > p {
  font-size: 35px;
  font-weight: 500;
}
.adminorderpage_table {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
}
.adminorderpage_table_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.adminorderpage_table_head > p {
  font-size: 30px;
}
.adminorderpage_table_head > span {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: 30px; */
  margin-right: 30px;
  width: 40%;
  border: 1px solid lightgrey;
  border-radius: 5px;
}
.adminorderpage_table_head > span > p {
  border-right: 1px solid lightgrey;
  padding-right: 5px;
  margin-right: 5px;
}
.adminorderpage_table_head > span > input {
  height: 53px;
  width: 85%;
  border: transparent;
  outline: transparent;
}
.adminorderpage_table_table {
  margin-top: 20px;
  width: 100%;
}
.adminorderpage_table_table > table {
  width: 100%;
}
.adminorderpage_table_table > table > thead > tr > th {
  text-align: justify;
  margin-bottom: 20px;
}
td,
th {
  border: 1px solid #ddd;
  padding: 12px 8px;
}

.adminsidebar_icon {
  margin-right: 10px;
}
.modal_form > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal_form > div > span {
  flex: 0.48;
  border: 1px solid lightgrey;
}
.modal_form > div > span > input {
  width: 100%;
  border: transparent;
  outline: transparent;
  height: 40px;
}
.modal_form > span > textarea {
  width: 100%;
  border: transparent;
  outline: transparent;
  padding-left: 10px;
  padding-top: 10px;
}
.modal_form_div {
  justify-content: flex-start !important;
}
.modal_form_div > div {
  margin-right: 15px;
}
.modal_form_div > div > p {
  margin-bottom: 5px;
}
.active_sidebar {
  color: #e68639 ;
  font-weight: 600;
 
}
