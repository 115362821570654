.blogpage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.blogpage_banner {
  width: 100%;
  height: 90vh;
  background-image: url("../imges/blog.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}
.blogpage_banner>h3{
    font-size: 40px;
    font-weight: 400;
    width: 70%;
    color: #fff;
    margin-bottom: 15px;
}
.blogpage_banner>span{
    border: 1px solid white;
    width: 40%;
    margin-top: 20px;
    margin-bottom: 20px;
}
.blogpage_banner>p{
    font-size: 25px;
    font-weight: 300;
    color: #fff;
    margin-top: 15px;
}
.blogpage_card{
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 83%;
}
@media only screen and (max-width:600px){
  .blogpage_banner{
    height: 30vh;
  }
  .blogpage_banner>h3 {
    font-size: 22px;
    width: 100%;
  }
  .blogpage_banner>p{
    font-size: 16px;
  }
}
@media only screen and (min-width:601px) and (max-width:1074px){
  .blogpage_banner{
    height: 65vh;
  }
  .blogpage_banner>h3{
    font-size: 30px;
    width: 90%;
  }
  .blogpage_banner>p{
    font-size: 18px;
  }
  .blogpage_card{
    width: 100%;
  }
}