.avaliableshowtable {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
  box-shadow: rgba(99, 99, 99, 0.1) 0px 2px 8px 0px;
  position: relative !important;
}
.avaliableshowtable > div {
  flex: 0.25;
  width: 100%;
  height: 100%;
  position: relative;
}
.avaliableshowtable > div > span {
  flex: 0.25;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid lightgrey;
  height: 100%;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid lightgrey;
  z-index: 99999 !important;
  min-height: 200px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.dropdown1 {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid lightgrey;
  z-index: 99999 !important;
  min-height: 200px;
  width: 800px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.dropdown1 > p {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 8px;
  border: 1px solid #e68639;
  padding: 6px 8px;
  border-radius: 6px;
  color: grey;
}
.dropdown2 {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid lightgrey;
  z-index: 99999 !important;
  min-height: 200px;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: pointer;
}
.dropdown > ul {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.dropdown > ul > li {
  width: 100%;
  padding: 15px 30px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.dropdown > ul > li:hover {
  background-color: #e68639;
  color: #fff;
}
.dropdown_active {
  background-color: #e68639;
  color: #fff;
}
.flip-2-hor-bottom-fwd {
  -webkit-animation: flip-2-hor-bottom-fwd 0.2s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-2-hor-bottom-fwd 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-14 13:42:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-2-hor-bottom-fwd
 * ----------------------------------------
 */
@-webkit-keyframes flip-2-hor-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
    transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(160px) rotateX(180deg);
    transform: translateY(100%) translateZ(160px) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}
@keyframes flip-2-hor-bottom-fwd {
  0% {
    -webkit-transform: translateY(0) translateZ(0) rotateX(0);
    transform: translateY(0) translateZ(0) rotateX(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
  }
  100% {
    -webkit-transform: translateY(100%) translateZ(160px) rotateX(180deg);
    transform: translateY(100%) translateZ(160px) rotateX(180deg);
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
  }
}
input[type="date"] {
  background-color: #e68639;
  padding: 15px;
  /* position: absolute; */
  /* transform: translate(-50%,-50%); */
  /* top: 50%;
    left: 50%; */

  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  border-radius: 5px;
}
::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}
.dropdown2 > span {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e68639;
  padding: 15px;
  border-radius: 5px;
}
.dropdown2 > span > p {
  margin-left: 5px;
}
@media only screen and (max-width: 600px) {
  .avaliableshowtable {
    flex-direction: column;
    margin-top: 75px;
    margin-bottom: 80px;
  }
  .avaliableshowtable > div >span{
    height: 45px;
    padding: 0 20px;
    justify-content: space-between;
    margin-bottom: 2px;
  }
  .dropdown1{
    width: 100%;
    height: 250px;
    overflow-x: scroll;
  }
  .dropdown2{
    width: 100%;
  }
}
