.navbar {
  width: 100%;
  padding: 0px 10px;
  /* border-bottom: 1px solid lightgrey; */
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 99999;
  
}
.navbar_border{
  border-bottom: 2px solid lightgrey;
}
nav {
  display: flex;
  align-items: center;

  justify-content: space-between;
  width: 100%;
}
.navbar_hamburger_mobile{
    display: none;
}
.navbar_first,
.navbar_last {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.navbar_second {
  flex: 0.15;
}
.navbar_first,
.navbar_last {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}
.navbar_first > a,
.navbar_last > a {
  text-decoration: none;
  color: #141414;
  font-size: 14px;
  font-weight: 500;
}
.navbar_first > a:hover,
.navbar_last > a:hover{
  color: #E68639;
}
.navbar_hamburger {
  position: relative;
}
.navbar_hamburger_menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  z-index: 9999;
  width: 200px;
  height: 220px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  border: 1px solid #e68639;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 25px;
}
.navbar_hamburger_menu > span {
  margin-bottom: 20px;
  border-bottom: 1px solid lightgrey;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 10px;
  cursor: pointer;
}
.navbar_hamburger_menu_icons {
  display: flex;
  align-items: center !important;
  justify-content: space-evenly !important;
  width: 100%;
}
/* .navbar_hamburger_menu_icon {
} */
.navbar_hamburger_div {
  width: 50px;
}
.navbar_hamburger_div>img{
  width: 25px;
}
.disable {
  opacity: 0.5;
}
.mySidenav {
  display: none;
}
.scale-in-hor-left {
	-webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-16 18:37:41
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-in-hor-left
 * ----------------------------------------
 */
 @-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
.isActive{
  color: #E68639;
}

@media only screen and (max-width: 1360px) and (min-width: 1230px) {
  .navbar_first > a,
  .navbar_last > a {
    font-size: 14px;
  }
  
}
@media only screen and (max-width: 1229px) and (min-width: 1130px) {
  .navbar_first > a,
  .navbar_last > a {
    font-size: 13px;
  }
}
@media only screen and (max-width: 600px) {
  .navbar_first,
  .navbar_last {
    display: none;
  }
  nav {
    justify-content: flex-start;
  }
  .navbar_second {
    flex: 0.9;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    background-color: #E68639;
    overflow-x: hidden;
    transition: 0.5s ease-in-out;
    padding-top: 30px;
    width: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .sidenav>span{
    width: 95%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .navbar_hamburger_mobile{
    display: inline-flex;
  }
  .sidenav_div{
    width: 90%;
    display: flex;
    align-items: center !important;
    justify-content: space-evenly !important;
    margin-top: 20px;
  }
  .navbar_hamburger_mobile>img{
    height: 20px;
  }
  .websiteLogo {
    height: 60px !important;
  }
}
@media only screen and (min-width: 601px) and (max-width: 1124px){
  .navbar_first,
  .navbar_last {
    display: none;
  }
  nav {
    justify-content: flex-start;
  }
  .navbar_second {
    flex: 0.9;
  }

  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1000000;
    top: 0;
    left: 0;
    background-color: #E68639;
    overflow-x: hidden;
    transition: 0.5s ease-in-out;
    padding-top: 30px;
    width: 0px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
  .sidenav>span{
    width: 95%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #fff;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  .navbar_hamburger_mobile{
    display: inline-flex;
  }
  .sidenav_div{
    width: 90%;
    display: flex;
    align-items: center !important;
    justify-content: space-evenly !important;
    margin-top: 20px;
  }
}
