.faq{
    width: 83%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
}
.faq>h3{
    margin-top: 20px;
}
.faq_cards{
    margin-top: 50px;
}
@media only screen and (max-width:600px){
    .faq{
        width: 91%;
    }
    .faq_cards{
        margin-top: 30px;
    }
}
@media (min-width: 601px) and (max-width: 1024px){
    .faq{
        width: 90%;
    }
    .faq_cards{
        margin-top: 30px;
    }
}