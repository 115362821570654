.blogcard{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 350px;
    height: 460px;
    border: 1px solid lightgrey;
    border-radius:4px ;
    margin-bottom: 20px;
}
.blogcard_img{
    width: 100%;
    height: 180px;
    padding: 5px;
}
.blogcard_img>img{
    width: 100%;
    height: 100%;
    object-fit: contain;
    
}
.blogcard_details{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    
}
.blogcard_details>h3{
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}
.blogcard_details_para{
    font-size: 14px;
    font-weight: 500px;
    text-align: justify;
    word-break: break-word;
    margin-bottom: 5px;
    margin-top: 5px;
}
.blogcard_details_para1{
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
    word-break: break-word;
    margin-bottom: 5px;
    color: #828282;
}
.blogcard_details_para2{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
}
.button{
    text-decoration: none;
    /* background-color: #E68639;
    color: #fff; */
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    border: 1px solid;
    color: #fff;
    background-color: #E68639;
}
.button:hover{
    opacity: 0.9;
}
@media only screen and (max-width:600px){
    .blogcard{
        height: 475px;
        min-width: 350px;
    }
}
@media (min-width: 601px) and (max-width: 1024px){
   .blogcard{
    min-width: 350px;
    margin-right: 20px;
   }
}
@media only screen and (min-width:1025px) and (max-width:1300px){
    .blogcard{
        margin-right: 20px;
        min-width: 350px;
    }
}