.home {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;



}
.home_para{
    color: #fff;
    font-size: 60px;
    font-weight: 400;
    /* margin-bottom: 50px; */
    /* position: absolute;
    top: 150px; */
    /* padding-bottom: 20px; */
    border-bottom-width:60% ;
    margin-bottom: 30px;
    width: 100%;
}
.home_para1{
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 80px;
    /* position: absolute;
    top: 250px; */
 
}
.home>button{
    padding: 10px 15px;
    font-size: 18px;
    font-weight: 500;
    border: transparent;
    outline: transparent;
    border-radius: 5px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.home>button:hover{
    opacity: 0.8;
}
.home_span{
    border: 1px solid #fff;
    width: 40%;
    margin-bottom: 30px;
}
