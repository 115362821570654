.ourspacecard{
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: row;
    min-width: 100%;
    padding-bottom: 2rem;
}
.ourspacecard_left{
    flex: 0.45;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.ourspacecard_left>img{
    height: 400px;
}
.ourspacecard_right{
    flex: 0.45;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    width: 100%;
    /* padding-bottom: 1rem; */
}
.ourspacecard_right>p{
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 30px;
    padding-bottom: 1rem;
}

.ourspacecardMain {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    overflow-x: scroll;
    /* min-width: 90%; */
}
@media only screen and (max-width:600px){
    .ourspacecard{
        flex-direction: column;
    }
    .ourspacecard_left{
        margin-bottom: 30px;
    }
    .ourspacecard_left>img{
        height: 300px;
    }
    
}
@media (min-width: 768px) and (max-width: 1024px){
    .ourspacecard{
        flex-direction: column;
    }
    .ourspacecard_left{
        margin-bottom: 20px;
    }
    .ourspacecard_left>img{
        width: 100%;
        height: 400px;
    }
}