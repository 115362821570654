.bookingcard {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  border: 1px solid lightgrey;
  width: 100%;
  height: 460px;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 4px;
}
.bookingcard_img {
  width: 100%;
  height: 200px;
  padding: 10px;
  background-color: #e68639;
}
.bookingcard_img > img {
  width: 100%;
  height: 180px;
 
}
.bookingcard_details {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  /* padding: 20px 40px; */
  /* min-height: 180px; */
  padding: 1rem;
  width: 100%;
  
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
.bookingcard_details>h3{
    font-size: 18px ;
    font-weight: 500;
}
.bookingcard_details>p{
    font-size: 12px;
    font-weight: 400;
    text-align: justify;
    opacity: 0.9;
    margin-top: 3px;
    margin-bottom: 3px;
}
.bookingcard_details>h6{
    font-size: 16px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 5px;
}
.bookingcard_details>span{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.bookingcard_details>span>h6{
    font-size: 16px;
    font-weight: 500;
    margin-right: 15px;
}
.bookingcard_details>span>strong{
    font-size: 16px;
    font-weight: 500;
    margin-right: 5px;
}
.bookingcard_button {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.bookingcard_button > a {
  margin-right: 10px;
}
.bookingcard:hover > .bookingcard_img > img {
    transition:0.5s ease-in-out;
  scale: 1.06;
}
.bookingcard:hover > .bookingcard_button {
  display: flex;
}
.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2023-6-8 12:37:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.button1{
  text-decoration: none;
  /* background-color: #E68639;
  color: #fff; */
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
  border: 1px solid;
  color: #141414;
  border: 1px solid #E68639;
}
.button1:hover{
  opacity: 0.9;
}
@media only screen and (max-width:600px){
  .bookingcard{
    /* margin-right: 20px; */
    min-width: 323px;
  }
  .infopage_six{
    width: 90%;
  }
  .infopage_five>p{
    font-size: 18px;
  }
}