.booknow {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 90vh;
}
.booknow_left {
  flex: 0.25;
  width: 100%;
  height: 100%;
  /* background-image: url("../imges/Group\ 12271.jpg"); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}

.booknow_left > img {
  width: 100%;
}
.booknow_left > span {
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.booknow_left > span > h3 {
  font-size: 25px;
  margin-bottom: 20px;
}
.booknow_left > span > p {
  margin-bottom: 20px;
}
.booknow_right {
  flex: 0.65;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* margin-top: 20px; */
  padding: 1rem;
}
.booknow_right_stepper {
  width: 650px;
  /* border: 1px solid lightgrey; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #d67e37;
  color: #fff;
  border-radius: 4px;
}
.booknow_right_stepper > div {
  flex: 0.3;
  width: 100%;
  height: 55px;
  border-right: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}
.booknow_right_stepper > div > span {
  margin-left: 10px;
}
.booknow_right_stepper > div > span > p {
  font-size: 14px;
  font-weight: 400;
}
.booknow_right_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  /* border: 1px solid lightgrey; */
}
.booknow_right_bottom_left {
  flex: 0.5;
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* justify-content: center; */
  /* justify-content: flex-start; */
  align-items: center;
  gap: 1rem;
  border-right: 1px solid lightgrey;
  height: 400px;
  padding: 1rem;
}
.booknow_right_bottom_left > h3 {
  font-size: 25px;
  font-weight: 400;
}
.booknow_right_bottom_right {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  /* padding-left: 30px; */
  padding: 1rem;
}
.booknow_right_bottom_right > h3 {
  font-size: 25px;
  font-weight: 400;
}
.booknow_right_bottom_right_timeSlots {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 1rem;
  margin-top: 30px;
}
.booknow_right_bottom_right_timeSlots > span {
  width: 125px;
  border: 2px solid #e68639;
  padding: 8px;
  padding-left: 10px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  margin-right: 30px;
}
.booknow_right_bottom_right_div {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  flex-direction: column;
}
.booknow_right_bottom_right_div > p {
  margin-bottom: 20px;
  font-size: 25px;
  font-weight: 400;
}
.booknow_right_bottom_right_div > input {
  width: 75%;
  height: 40px;
  padding-left: 15px;
  outline: transparent;
}
.booknow_right_bottom_right > button {
  margin-top: 30px;
  padding: 8px 10px;
  outline: transparent;
  border: transparent;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  background-color: #d67e37;
  border-radius: 4px;
  cursor: pointer;
}
.seats {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.seats > h3 {
  color: black;
  margin-top: 20px;
}
.screens {
  margin-top: 30px;
  background-color: black;
  width: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen {
  color: red;
}
.seat-price {
  margin-top: 30px;
  display: flex;
  background-color: rgb(83, 199, 235);
  width: 80%;
  justify-content: center;
  align-items: center;
}

.seat-select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.seats-select {
  font-size: 20px;
  margin-left: 20px;
}
.price {
  font-size: 20px;
}
.totalprice {
  margin-left: 50%;
}
.continue {
  background-color: rgb(9, 41, 51);
  margin-top: 20px;
  border-radius: 3px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  color: forestgreen;
}
.booknowticket {
  margin-top: 50px;
  width: 100%;
}
.booknowticket > button {
  margin-top: 50px;
  padding: 8px 10px;
  outline: transparent;
  border: transparent;
  font-size: 25px;
  font-weight: 400;
  color: #fff;
  background-color: #d67e37;
  border-radius: 4px;
  cursor: pointer;
}
.booknowpayment {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  /* border: 1px solid lightblue; */
}
.booknowpayment_timer {
  display: flex;
  align-items: flex-end;

  justify-content: flex-end;
  width: 80%;
  margin-bottom: 30px;
}
.booknowpayment_timer > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border: 1px solid #2c3798;
  background: rgba(137, 213, 255, 0.1);
  border-radius: 6px;
}
.booknowpayment_timer > span > p {
  margin-left: 5px;
}
.booknowpayment_border {
  width: 85%;
  border: 1px solid lightgrey;
  margin-bottom: 30px;
  margin-top: 10px;
}
.booknowpayment_bottom {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 80%;
}
.booknowpayment_bottom_left {
  flex: 0.45;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.booknowpayment_bottom_left > form {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.booknowpayment_bottom_left > form > span {
  width: 80%;

  background-color: #f5f5f5;
  margin-bottom: 20px;
}
.booknowpayment_bottom_left > form > span > input {
  width: 100%;
  background-color: transparent;
  height: 45px;
  border: transparent;
  outline: transparent;
  padding-left: 10px;
  border-radius: 6px;
}
.booknowpayment_bottom_left > form > button {
  padding: 8px 10px;
  outline: transparent;
  border: transparent;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background-color: #d67e37;
  border-radius: 4px;
  cursor: pointer;
}
.booknowpayment_bottom_right {
  flex: 0.45;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
}
.booknowpayment_bottom_right_top {
  width: 100%;
  /* border: 1px solid; */
  background-color: #f5f5f5;
  padding: 10px;
}
.booknowpayment_bottom_right_top > div {
  padding-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
}
.booknowpayment_bottom_right_top_div {
  border-bottom: none !important;
}
.booknowpayment_bottom_right_bottom {
  margin-top: 10px;
  width: 100%;
  background-color: #f5f5f5;
  padding: 10px;
  color: #a20f37;
}
.booknowticket_type {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 30px;
}
.booknowticket_type > span > img {
  width: 20px;
  height: 20px;
}
.booknowticket_seat {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.booknowticket_seat_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
}
.booknowticket_seat_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.booknowticket_seat_right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
}
.booknowticket_seat_left>span,.booknowticket_seat_center>span,.booknowticket_seat_right>span{
  margin-bottom: 5px;
}
.movieseat{
  width: 25px;
  height: 25px;
  border: 1px solid black;
  background-color: #e9e9e9;
  border: 1px solid #fff;
}
input[type="checkbox"] {
  /* ...existing styles */
  width: 25px;
  height: 25px;
  /* position: relative; */
  /* cursor: pointer; */
  margin-right: 4px;
  margin-bottom: 4px;
}

input[type="checkbox"]:before {
  content: "";
  display: block;
  /* position: absolute; */
  width: 25px;
  height: 25px;
  /* top: 0;
  left: 0; */
  background-color: #e9e9e9;
  border: 1px solid #fff;
}
input[type="checkbox"]:checked:before {
  content: "";
  display: block;
  /* position: absolute; */
  width: 25px;
  height: 25px;
  /* top: 0;
  left: 0; */
  background-color: #e68639;
}
input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  /* border: solid white; */
  /* border-width: 0 2px 2px 0; */
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  /* position: absolute;
  top: 2px;
  left: 6px; */
}
.booknowticket_screen {
  width: 100%;
}
.booknowticket_screen > img {
  width: 100%;
}
.booknowticket_select_seat {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 40px;
}
.booknowticket_select_seat > span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.booknowticket_select_seat > span > h3 {
  margin-right: 5px;
  color: #e68639;
}
.booknow_right_bottom_right_span_para {
  color: "#316EA6";
}
.activeslot {
  color: #fff;
  background-color: #e68639;
}
.booknowticket_select_seat_para {
  max-width: 150px;
  overflow-x: scroll;
  display: flex;
}
.booknowticket_select_seat_para::-webkit-scrollbar {
  display: none;
}
.error_message1 {
  margin-top: -10px;
}
@media only screen and (max-width: 600px) {
  .booknow_left {
    display: none;
  }
  .booknow_right_bottom {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .booknow_right_stepper {
    width: 98%;
  }
  .booknow_right_bottom_left {
    border-right: none;
  }
  .booknowpayment_bottom_right_top {
    width: 100%;
  }
  .booknowpayment_bottom_right_bottom {
    width: 100%;
    margin-bottom: 20px;
  }
  .booknowpayment_bottom {
    margin-top: 0;
  }
  .booknowpayment_bottom_left > form > span {
    width: 100%;
  }
  .booknowticket_select_seat_para {
    max-width: 70px;
  }
  .booknowthankyoupage_background {
    width: 90%;
  }
  .booknow_right {
    flex: 1;
    width: 100%;
  }
  .booknowticket_select_seat > span > h3 {
    font-size: 14px;
  }
  .booknowticket_seat {
    align-items: flex-start;
    justify-content: flex-start;
    overflow-x: scroll;
  }
  .booknowpayment_bottom {
    width: 90%;
    flex-direction: column-reverse;
  }
  input[type="checkbox"] {
    /* ...existing styles */
    width: 19px;
    height: 19px;
    /* position: relative; */
    cursor: pointer;
  }
  
  input[type="checkbox"]:before {
    content: "";
    display: block;
    /* position: absolute; */
    width: 19px;
    height: 19px;
    /* top: 0;
    left: 0; */
    background-color: #e9e9e9;
    border: 3px solid #fff;
  }
  input[type="checkbox"]:checked:before {
    content: "";
    display: block;
    /* position: absolute; */
    width: 19px;
    height: 19px;
    /* top: 0;
    left: 0; */
    background-color: #e68639;
  }
  input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    width: 19px;
    height: 19px;
    /* border: solid white; */
    /* border-width: 0 2px 2px 0; */
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    /* position: absolute;
    top: 2px;
    left: 6px; */
  }
  .booknowticket_seat_left{

    margin-right: 0;
  }
  .booknowticket_seat_right{
    margin-left: 0;
  }
  .booknowticket_select_seat {
    flex-direction: column;
    padding: 0 100px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .booknowticket_seat_left>span,.booknowticket_seat_center>span,.booknowticket_seat_right>span{
    display: flex;
  }
}
@media only screen and (min-width: 600px) and (max-width: 1074px) {
  .booknow_left {
    display: none;
  }
  .booknow_right {
    flex: 1;
  }
  .booknowpayment_bottom_left > form > span > input {
    height: 50px;
  }
  .booknowpayment_bottom_left > form > span {
    width: 100%;
  }
}
