.aboutus {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  width: 90%;
  margin-top: 50px;
  margin-top: 100px;
  margin-bottom: 30px;
}
.aboutus_left {
  flex: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid lightgrey; */
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 4px;
}
.aboutus_left > img {
  width: 100%;
  height: 500px;
  position: absolute;
  top: -30px;

  left: 30px;
}
.aboutus_right {
  flex: 0.45;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.aboutus_right > p {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: justify;
  word-break: break-word;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}
@media only screen and (max-width: 600px) {
  .aboutus {
    flex-direction: column;
    margin-top: 30px;
  }
  .aboutus_left {
    flex: 1;
    height: 300px;
    width: 100%;
    margin-bottom: 30px;
  }
  .aboutus_left > img {
    position: relative;
    height: 275px;
    left: 10px;
    top: -10px;
  }
  .aboutus_right {
    flex: 1;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
    .aboutus{
        flex-direction: column;
    }
    .aboutus_left{
        margin-bottom: 20px;
    }
    .aboutus_left > img{
        position: relative;
        height: 400px;
        width: 98%;
        left: 20px;
      
    }
}
