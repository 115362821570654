.booknowthankyoupage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.booknowthankyoupage_background {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
  height: 80vh;
  width: 80%;
  background: linear-gradient(
    108.75deg,
    #e68639 41.08%,
    rgba(214, 126, 55, 0.77) 119.24%
  );
  border-radius: 8px;
  color: #fff;
}
.booknowthankyoupage_background>h3{
    font-size: 25px;
    font-weight: 400px;
    margin-bottom: 10px;
}
.booknowthankyoupage_background>p,.booknowthankyoupage_background>strong{
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 400;
}
.booknowthankyoupage_background_para{
    width: 50%;
}