.admindashboard {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.admindashboard_first {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 30px;
}
.admindashboard_first > div {
  width: 300px;
  color: #fff;
  padding: 10px 15px;
  border: 1px solid lightgrey;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 5px;
}
.admindashboard_first > div > p {
  font-size: 25px;
  font-weight: 500;
  margin-bottom: 15px;
}
.admindashboard_first > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.admindashboard_first > div > div > span {
  border: 1px solid;
  padding: 15px;
  border-radius: 50%;
  background-color: #fff;
}
.admindashboard_first > div > div > span > img {
  height: 50px;
}
.admindashboard_first > div > div > strong {
  font-size: 25px;
}
.admindashboard_second{
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
}
.admindashboard_second>p{
    font-size: 25px;
    font-weight: 500;
}