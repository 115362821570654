.adminnavbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  /* border: 1px solid lightgrey; */
  padding: 5px;
  border-radius: 5px;
}
.adminnavbar > span {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  width: 250px;
  border: 1px solid lightgrey;
}
.adminnavbar > span > input {
  width: 90%;
  border: transparent;
  outline: transparent;
  padding-left: 5px;
}
.adminnavbar>div{
    display: flex;
    align-items: center;
    justify-content: center;
}
.adminnavbar_icon{
    margin-right: 8px;
}
.adminnav_right > div {
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.adminnav_right > div > span {
  display: none;
}
.adminnav_right > div:hover > span {
  padding: 8px;
  width: 100px;
  background-color: #dddddd;
  position: absolute;
  top: 100%;
  border-radius: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
}
.adminnav_right > div > span > p {
  cursor: pointer;
  margin-bottom: 5px;
  width: fit-content;
}
.adminnav_right > div > span > p:hover {
  color: #5329ac;
}